import UserProfile from "views/admin/UserProfile";
import Proposals from "./views/admin/Proposals";

import Support from "views/admin/Support/index.jsx";
import NotFound from "views/admin/NotFound";
import IncompleteProposal from "views/admin/IncompleteProposal";
import DailyValues from "views/admin/DailyValues";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  // Manter a home sempre na primeira posição
  // {
  //   path: "/home",
  //   name: "Página inicial",
  //   sidebarname: "Página inicial",
  //   icon: "fas fa-home",
  //   component: Home,
  //   layout: "/admin",
  // },
  {
    path: "/home",
    name: "Propostas", //Nome dentro do componente
    icon: "fas fa-clipboard", //Ícone da sidebar
    component: Proposals,
    layout: "/admin",
  },
  {
    path: "/incomplete-proposals",
    name: "Propostas incompletas",
    icon: "far fa-clipboard",
    component: IncompleteProposal,
    layout: "/admin",
  },
  {
    path: "/daily-values",
    name: "Valores diários",
    icon: "fas fa-calendar-week",
    component: DailyValues,
    layout: "/admin",
  },
  // Manter o support e notfound sempre nas últimas posições respectivamente
  {
    path: "/suporte",
    name: "Suporte", //Nome dentro do componente
    icon: "far fa-question-circle", //Ícone da sidebar
    component: Support,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
