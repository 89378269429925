import React from 'react';

import Page from "components/Page";
import TableOrders from 'components/TableOrders';

export default function Proposals() {
  return (
    <Page>
        <TableOrders />
    </Page>
  );
}
