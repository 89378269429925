import Page from 'components/Page';
import TableDailyValues from 'components/TableDailyValues';
import React from 'react';

export default function DailyValues() {
  return (
    <Page>
        <TableDailyValues />
    </Page>
  );
}
