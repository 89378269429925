import Page from 'components/Page';
import TableOrders from 'components/TableOrders';
import React from 'react';

export default function IncompleteProposal() {
  return (
    <Page>
      <TableOrders 
        incompleteProposals={true}
      />
    </Page>
  )
}
