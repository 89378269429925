import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Table } from "reactstrap";

import Loading from "components/Loading";

import valueMask from "common/valueMask";
import CustomPagination from "components/CustomPagination";
import errorRequest from "common/errorRequest";
import { useHistory } from "react-router-dom";
import api from "config/api";

export default function TableDailyValues() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [dailyValues, setDailyValues] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);

  const limit = 5;

  useEffect(() => {
    const fetchDailyValues = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/dailys?offset=${limit * (page - 1)}&limit=${limit}&status=${status}`
        );

        setDailyValues(data[0]);
        setTotalPage(data[1]);
      } catch (error) {
        errorRequest(history, error);
      }

      setLoading(false);
    };
    fetchDailyValues();
  }, [page, status, history]);

  return (
    <>
      {loading && <Loading />}
      <Row className="mb-4">
        <Col md="4">
          <Form>
            <Input
              type="select"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option key="order-status-1" value={1}>
                Todos
              </option>
              <option key="order-status-2" value={2}>
                Últimos 7 dias
              </option>
              <option key="order-status-3" value={3}>
                Últimos 30 dias
              </option>
              <option key="order-status-4" value={4}>
                Últimos 3 meses
              </option>
              <option key="order-status-5" value={5}>
                Últimos 6 meses
              </option>
              <option key="order-status-6" value={5}>
                Último ano
              </option>
            </Input>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped>
            <thead>
              <tr>
                <th className="TableTh">Data</th>
                <th className="TableTh">Valor</th>
              </tr>
            </thead>
            <tbody>
              {dailyValues && dailyValues.length === 0 ? (
                <tr>
                  <td className="TableTd">
                    Não foi encontrado nenhum registro...
                  </td>
                </tr>
              ) : (
                dailyValues &&
                dailyValues.map((dailyValue) => (
                  <tr key={dailyValue.id}>
                    <td className="TableTd">
                      {new Date(dailyValue.created_at).toLocaleDateString(
                        "pt-br"
                      )}
                    </td>
                    <td className="TableTd">
                      {valueMask.money(dailyValue.value)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          {totalPage * limit > limit && (
            <div className="d-flex justify-content-center my-3">
              <CustomPagination
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                limit={limit}
                length={dailyValues && dailyValues.length}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
