import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Row,
  InputGroup,
  Input,
  Button,
  Col,
  FormGroup,
  Table,
} from "reactstrap";

import api from "config/api";

import CustomPagination from "components/CustomPagination";
import Loading from "components/Loading";

import errorRequest from "common/errorRequest";
import valueMask from "common/valueMask";

import "components/TableOrders/styles.scss";

export default function TableOrders({ incompleteProposals = false }) {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(incompleteProposals ? 6 : 1);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const haveFetchOrders = useRef(true);
  const limit = 15;

  useEffect(() => {
    haveFetchOrders.current = true;
  }, [page, history, status])

  const fetchOrders = useCallback(async () => {
    let newSearch = "";
    setLoading(true);
    try {
      if (/\d/.test(search)) {
        newSearch = search.replace(/[^0-9]/g, "");
      }

      const { data } = await api.get(
        `/proposals?status=${status}&search=${
          newSearch || search
        }&limit=${limit}&offset=${limit * (page - 1)}`
      );
      setOrders(data[0] || data.proposta[0]);
      setTotalPages(data[1] || data.proposta[1]);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }, [page, history, search, status]);

  useEffect(() => {
    if (haveFetchOrders.current) {
      fetchOrders();
      haveFetchOrders.current = false;
    }
  }, [fetchOrders]);


  return (
    <>
      {loading && <Loading />}
      <Row className="mb-4">
        <Col md="4" className="mb-4">
          <InputGroup>
            <Input
              placeholder="Buscar..."
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={(e) => e.keyCode === 13 && fetchOrders()}
            />
            <Button className="table-search-button" onClick={fetchOrders}>
              <i className="fas fa-search" />
            </Button>
          </InputGroup>
        </Col>
        {!incompleteProposals && (
          <Col md="4">
            <FormGroup>
              <Input
                type="select"
                onChange={(e) => {
                  setStatus(Number(e.target.value))
                  setPage(1)
                }}
                value={status}
              >
                <option
                  key="order-status-1"
                  value={incompleteProposals ? 6 : 1}
                >
                  Todos
                </option>
                <option key="order-status-3" value={3}>
                  Pagas Hoje
                </option>
                <option key="order-status-2" value={2}>
                  Todas as propostas pagas
                </option>
                <option key="order-status-4" value={4}>
                  Propostas canceladas
                </option>
                <option key="order-status-5" value={5}>
                  Propostas pendentes de assinatura
                </option>
                <option key="order-status-7" value={7}>
                  Outros
                </option>
              </Input>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="container-table">
          <Table striped>
            <thead>
              <tr>
                <th className="TableTh">Nome</th>
                <th className="TableTh">CPF</th>
                <th className="TableTh">TELEFONE</th>
                <th className="TableTh">PROPOSTA</th>
                <th className="TableTh">STATUS</th>
                <th className="TableTh">DATA</th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.length === 0 ? (
                <tr>
                  <td colSpan="7">Não foi encontrado nenhum registro...</td>
                </tr>
              ) : (
                orders &&
                orders.map((order) => (
                  <tr key={`${order.nuvemshop_order_id}-${order.id}`}>
                    <td className="TableTd">{order.name}</td>
                    <td className="TableTd">{valueMask.cpf(order.cpf)}</td>
                    <td className="TableTd">{valueMask.phone(order.phone)}</td>
                    <td className="TableTd text-center">
                      {order.safra_proposal ? order.safra_proposal : "-"}
                    </td>
                    <td className="TableTd overflow-visible">
                      <div className="action-button-hover">
                        {order.status.toLowerCase()}
                        <div className="button-baloon">
                          {order.status.toLowerCase()}
                        </div>
                      </div>
                    </td>
                    <td className="TableTd">
                      {new Date(order.created_at).toLocaleDateString("pt-br")}<br/>
                      às {new Date(order.created_at).getHours()}:{new Date(order.created_at).getMinutes().toString().padStart(2, '0')}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          {totalPages * limit > limit && (
            <div className="d-flex my-3 justify-content-center">
              <CustomPagination
                page={page}
                total={totalPages * limit}
                limit={limit}
                setPage={setPage}
                length={orders && orders.length}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
