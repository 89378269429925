import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardImg,
} from "reactstrap";

import Loading from "components/Loading";
import api from "config/api";

import "./styles.scss";
import Swal from "sweetalert2";
import logo from "../../../assets/img/logo_dark_crm.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  async function submit() {
    if (!email || !password) {
      setError("Preencha o email e senha por favor!");
      return;
    }

    // valida o e-mail
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(String(email).toLowerCase())) {
      setError("Formato de e-mail inválido!");
      return;
    }

    setLoading(true);
    try {
      const { data } = await api.post("authenticate", { email, password });
      localStorage.setItem("token", data[1].token);
      localStorage.setItem("name", data[0].name);

      history.push("/admin/home");
    } catch (error) {
      if (error.response.data) {
        if (error.response.data.banned) {
          setError(
            "Seu cadastro foi banido, fale com o administrador para saber o que houve"
          );
        } else if (error.response.data.deleted) {
          Swal.fire({
            icon: "info",
            title: "Bem-vindo(a) de volta",
            text: "Deseja reativar seu cadastro?",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
          }).then(async (res) => {
            if (res.value) {
              setLoading(true);
              try {
                const { data } = await api.post("/reactive", {
                  email,
                  password,
                });

                localStorage.setItem("token", data.token);
                localStorage.setItem("name", data.name);
                history.push("/admin/home");
              } catch (error) {
                Swal.fire("Erro", error.response.data.message, "error");
              }
              setLoading(false);
            }
          });
        } else {
          if (!error.response.data.emailChecked) {
            history.push("/unconfirmed-email", { email });
          }
        }
      } else {
        setError(error.response.data.message);
      }
    }
    setLoading(false);
  }
  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Credenciais</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    maxlenght="100"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) submit();
                    }}
                    maxLength="24"
                  />
                </InputGroup>
              </FormGroup>
              {error && <small className="error-info">{error}</small>}
              <div className="text-center">
                <Button
                  onClick={() => submit()}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Acessar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link to="/forgot-password">
              <small>Esqueceu sua senha?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
